<template lang="pug">
page.bf2042.ff-barlow(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Header
  base-header(
    slot='header',
    :showHeaderLinks='false',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )

  //- Content
  template(slot='content')
    //- Banner
    page-section.bf2042-section.bf2042-section--banner(:fullWidth='true', align='center')
      template(slot='content')
        .banner-img
        .banner-img-bg

    //- Main
    #main
      page-section.bf2042-section.bf2042-section--enter.page-pt-md-alt.page-pb-md(align='center')
        template(slot='content')
          h1.ff-barlow-condensed Battlefield 2042 Gold Edition Giveaway!
          .fs-lg.page-mb-md Choose your region and platform

          .page-mt-md-alt
            .fs-lg.fw-bold.mb-2 PlayStation&reg;
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("FpjKzF9")',
              target='_blank',
              @click='trackGiveawayClick("FpjKzF9")'
            )
              .copy North America
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("GIiUEXO")',
              target='_blank',
              @click='trackGiveawayClick("GIiUEXO")'
            )
              .copy Europe
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("Hi2dPO1")',
              target='_blank',
              @click='trackGiveawayClick("Hi2dPO1")'
            )
              .copy Asia Pacific
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("U0C0ByM")',
              target='_blank',
              @click='trackGiveawayClick("U0C0ByM")'
            )
              .copy Latin America
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("8PCgaob")',
              target='_blank',
              @click='trackGiveawayClick("8PCgaob")'
            )
              .copy Japan
            b-btn.mx-2.mb-3(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("ytMMGXY")',
              target='_blank',
              @click='trackGiveawayClick("ytMMGXY")'
            )
              .copy South Korea
          .page-mt-md
            .fs-lg.fw-bold.mb-2 Xbox
            b-btn.mx-2.mb-2(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("pk752mr")',
              target='_blank',
              @click='trackGiveawayClick("pk752mr")'
            )
              .copy All Eligible Regions
          .page-mt-md
            .fs-lg.fw-bold.mb-2 PC/Steam&reg;
            b-btn.mx-2(
              variant='bf-teal',
              :href='$helpers.giveawayUrl("r8xsLEP")',
              target='_blank',
              @click='trackGiveawayClick("r8xsLEP")'
            )
              .copy All Eligible Regions
</template>
<script>
import BaseHeader from '../components/BaseHeader'
import gaEventMixin from '../mixins/ga_event'

export default {
  name: 'Battlefield2042',
  mixins: [gaEventMixin],
  methods: {
    trackGiveawayClick(idToken) {
      this.trackEvent(`to_giveaway__${idToken}`)
    }
  },
  metaInfo() {
    return {
      title: 'BATTLEFIELD 2042 Gold Edition Giveaway',
      titleTemplate: '%s | Surf Giveaways'
    }
  },
  components: {
    BaseHeader
  }
}
</script>
<style lang="scss" src="../assets/themes/bf2042/scss/main.scss" />
